@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;0,700;1,300&display=swap');

.app__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  background-color: #acbac9;
  // background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  // border: 1px solid rgba(255, 255, 255, 0.18);
  position: fixed;
  z-index: 2;
  margin-bottom: 2rem;
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  // align-items: center;

  img {
    // width: 40px;
    // height: 40px;

    @media screen and (min-width: 2000px) {
      width: 180px;
      height: 40px;
    }
  }
}

.logo {
  position: relative;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-size: 1.5em;
  line-height: 2rem;
  letter-spacing: 1px;
  font-weight: 500;
  overflow: hidden;
  background: linear-gradient(90deg, #3f3e92, #070142, #000);
  background-repeat: no-repeat;
  background-size: 60%;
  animation: animate 4s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;

  li {
    width: 8rem;
    margin:  1rem;
    padding: 1.5rem;
    cursor: pointer;
    flex-direction: column;
    background-color: #002bff;

    div {
      width: 10px;
      height: 10px;
      background: transparent;
      border-radius: 50%;
      margin-bottom: 5px;
    }
    a {
     display: inline-block;
      font-size: 1.2rem;
      color: #ffffff;
      text-decoration: none;
      flex-direction: column;

      text-transform: uppercase;
      font-weight: 500;

      transition: all 0.3s ease-in-out;

      &:hover {
        // color: var(--secondary-color);
      }
    }
    &:hover {
      div {
        // background-color: var(--secondary-color);
      }
    }
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.app__navbar-menu {
  //   width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: var(--secondary-color);

  svg {
    width: 70%;
    height: 70%;
    color: #313bac;
    margin: 0.5rem 1rem;
  }

  div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    padding: 1rem;
    width: 80%;
    height: 100vh;

    display: flex;
    justify-content: flex-end;

    align-items: flex-end;
    flex-direction: column;

    background: url("../../assets/circle.svg");
    background-color: var(--white-color);
    background-size: cover;
    background-repeat: repeat;
    box-shadow: 0, 0, 20px rgba(168, 168, 168, 0.15);
    object-fit: cover;

    svg {
      width: 35px;
      height: 35px;
      color: var(--secondary-color);
      margin: 0.5rem 1rem;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      li {
        margin: 1rem;
        width: 8rem;

        a {
          color: var(--white-color);
          text-decoration: none;
          font-size: 1.5rem;
          text-transform: uppercase;
          font-weight: 500;

          transition: all 0.3s ease-in-out;

          &:hover {
            color: var(--black-color);
          }
        }
      }
    }

    @media screen and (min-width: 900px) {
      display: none;
    }
  }
  @media screen and (min-width: 900px) {
    display: none;
  }
}

// * Page Links */
.glow-on-hover {
  width: 100px;
  text-align: center;
  height: 30px;
  border: none;
  outline: none;
  color: #ffffff;
  // background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;

  &:before {
    content: "";
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    );
    background-image: linear-gradient(to right top);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }
  &:active {
    color: #000000;
  }
  &:active:after {
    background: transparent;
  }
  &:hover:before {
    opacity: 1;
  }
  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #313bac;
    left: 0;
    top: 0;
    border-radius: 20px 10px;
  }
}
@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
